import React, { useState } from "react";
import Mantra from "./components/Mantra";
import ToggleContainer from "./components/ToggleContainer";
import mantras from "./info";
import "./styles/App.css";
import "./components/UI/rightWindow.css";
import clanImage from "./components/UI/image/clan.png";

function App() {
  const [selectedMantra, setSelectedMantra] = useState(null);

  const handleMantraClick = (info) => {
    setSelectedMantra(info);
  };

  return (
    <div className="App">
      <div className="versionBlock">
        <h2>V2.0</h2>
      </div>
      <div className="Main">
        <div className="leftContainer">
          <div className="leftWindow">
            <ToggleContainer
              title="FlameCharm"
              id="flameCharm"
              sharedStyle={{ color: "orange" }}
            >
              <Mantra
                info={mantras.flameCharm.fireBlade.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.flameCharm.flameGrab.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.flameCharm.flameRepulsion.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.flameCharm.burningServants.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.flameCharm.fireGun.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.flameCharm.flameOfDenial.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.flameCharm.flameBlind.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.flameCharm.firePalm.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.flameCharm.fireEruption.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.flameCharm.fireForge.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.flameCharm.gracefulFlame.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.flameCharm.flameSentinel.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.flameCharm.flameWisp.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.flameCharm.flameLeap.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.flameCharm.flareVolley.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.flameCharm.flameAssault.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.flameCharm.risingFlame.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.flameCharm.relentlessFlames.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.flameCharm.ashSlam.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.flameCharm.flameBallista.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.flameCharm.flamingScourge.info}
                onClick={handleMantraClick}
              />
            </ToggleContainer>
            <ToggleContainer
              id="thundercall"
              title="ThunderCall"
              sharedStyle={{ color: "yellow" }}
            >
              <Mantra
                info={mantras.thunderCall.LightningBlade.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.thunderCall.JoltGrab.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.thunderCall.LightningBeam.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.thunderCall.LightningStrike.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.thunderCall.FleetingSparks.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.thunderCall.LightningStream.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.thunderCall.LightningImpact.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.thunderCall.LightningClones.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.thunderCall.ElectroCarve.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.thunderCall.ThunderKick.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.thunderCall.BoltPiercer.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.thunderCall.GrandJavelin.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.thunderCall.LightningAssault.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.thunderCall.ThunderWisp.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.thunderCall.StormBlades.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.thunderCall.LightningCloak.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.thunderCall.RisingThunder.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.thunderCall.EmotionWave.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.thunderCall.sparksSwap.info}
                onClick={handleMantraClick}
              />
            </ToggleContainer>
            <ToggleContainer
              title="FrostDraw"
              className="FrostDraw"
              sharedStyle={{ color: "#4682B4" }}
            >
              <Mantra
                info={mantras.frostDraw.frozenServants.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.frostDraw.IceBeam.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.frostDraw.wardensBlades.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.frostDraw.iceSpikes.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.frostDraw.iceDaggers.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.frostDraw.IceBlade.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.frostDraw.IceChain.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.frostDraw.iceEruption.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.frostDraw.iceForge.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.frostDraw.IceSmash.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.frostDraw.GlacialArc.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.frostDraw.IceLance.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.frostDraw.RisingFrost.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.frostDraw.FrostGrab.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.frostDraw.IceCarve.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.frostDraw.FrostWisp.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.frostDraw.iceLaser.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.frostDraw.crystalKnee.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.frostDraw.crystaImpale.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.frostDraw.Iceberg.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.frostDraw.IceSkate.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.frostDraw.IceCubes.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.frostDraw.iceFissure.info}
                onClick={handleMantraClick}
              />
            </ToggleContainer>
            <ToggleContainer
              title="GaleBreath"
              className="GaleBreath"
              sharedStyle={{ color: "lightgreen" }}
            >
              <Mantra
                info={mantras.galeBreath.WindBlade.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.galeBreath.AirForce.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.galeBreath.TornadoKick.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.galeBreath.windForge.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.galeBreath.GaleLunge.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.galeBreath.TornadoW.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.galeBreath.twisterKicks.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.galeBreath.heavenlyWind.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.galeBreath.galetrap.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.galeBreath.galePunch.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.galeBreath.championsW.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.galeBreath.risingWind.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.galeBreath.windGun.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.galeBreath.windCarve.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.galeBreath.galeWisp.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.galeBreath.astralWind.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.galeBreath.pillarsOfErisia.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.galeBreath.windPassage.info}
                onClick={handleMantraClick}
              />
            </ToggleContainer>
            <ToggleContainer
              title="ShadowCast"
              className="ShadowCast"
              sharedStyle={{ color: "black" }}
            >
              <Mantra
                info={mantras.shadowCast.darkBlade.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.shadowCast.shadowGun.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.shadowCast.clutchingShadow.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.shadowCast.shadowChains.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.shadowCast.shadowEruption.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.shadowCast.shadowSeekers.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.shadowCast.encircle.info}
                onClick={handleMantraClick}
              />

              <Mantra
                info={mantras.shadowCast.shadeDevour.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.shadowCast.shadeStep.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.shadowCast.shadowRoar.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.shadowCast.risingShadow.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.shadowCast.shadowMeteor.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.shadowCast.devouringEye.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.shadowCast.shadeWisp.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.shadowCast.shadowAssault.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.shadowCast.eclipseKick.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.shadowCast.shadeBringer.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.shadowCast.shadowVortex.info}
                onClick={handleMantraClick}
              />
            </ToggleContainer>
            <ToggleContainer
              title="IronSing"
              className="IronSing"
              sharedStyle={{ color: "gray" }}
            >
              <Mantra
                info={mantras.ironSing.metalEruption.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.ironSing.needleBarrage.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.ironSing.metalRampart.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.ironSing.metalKick.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.ironSing.ironSkin.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.ironSing.chainPull.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.ironSing.metalTurret.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.ironSing.ironQuills.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.ironSing.metalRain.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.ironSing.caltrops.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.ironSing.firingLine.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.ironSing.metalFake.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.ironSing.oxidizingRush.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.ironSing.metalBall.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.ironSing.MetalWisp.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.ironSing.MetalArmament.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.ironSing.MetalGatling.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.ironSing.IronSlam.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.ironSing.IronHug.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.ironSing.RocketLance.info}
                onClick={handleMantraClick}
              />
            </ToggleContainer>
            <ToggleContainer
              title="BloodRend"
              className="BloodRend"
              sharedStyle={{ color: "darkred" }}
            >
              <Mantra
                info={mantras.bloodRend.Crucifixion.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.bloodRend.scarletCyclone.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.bloodRend.bloodOrb.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.bloodRend.BloodStakes.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.bloodRend.SanguineDive.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.bloodRend.viciousDescent.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.bloodRend.Bloodedge.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.bloodRend.BloodWisp.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.bloodRend.soulflareSiphon.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.bloodRend.veinbreaker.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.bloodRend.crymsonRain.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.bloodRend.veinTendrils.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.bloodRend.scarletCannon.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.bloodRend.razorBlitz.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.bloodRend.crimsonSurge.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.bloodRend.bloodtideRitual.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.bloodRend.bloodcurdle.info}
                onClick={handleMantraClick}
              />
            </ToggleContainer>
            <ToggleContainer
              title="LifeWeave"
              className="LifeWeave"
              sharedStyle={{ color: "turquoise" }}
            >
              <div className="lifeweave">
                <img
                  src="https://media.tenor.com/CNbHipPnXwcAAAAi/deepwoken-deepwoken-conquest.gif"
                  alt="lifeweave"
                />
              </div>
            </ToggleContainer>
            <ToggleContainer
              title="Hybrid"
              className="Hybrid"
              sharedStyle={{ color: "chartreuse" }}
            >
              <Mantra
                info={mantras.hybrid.flashfireSweep.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.hybrid.ironTether.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.hybrid.permafrostPrison.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.hybrid.tempestBlitz.info}
                onClick={handleMantraClick}
              />
            </ToggleContainer>
            <ToggleContainer
              title="Attunementless"
              className="Attunementless"
              sharedStyle={{ color: "mintcream" }}
            >
              <Mantra
                info={mantras.attumentLess.TableFlip.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.attumentLess.strongLeft.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.attumentLess.rapidPunches.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.attumentLess.skyshatterKick.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.attumentLess.strongLeap.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.attumentLess.karitaLeap.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.attumentLess.karitaDivebomb.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.attumentLess.tacetDropKick.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.attumentLess.rally.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.attumentLess.reinforce.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.attumentLess.brace.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.attumentLess.shoulderBash.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.attumentLess.dash.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.attumentLess.revenge.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.attumentLess.adrenalineSurge.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.attumentLess.summonCauldron.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.attumentLess.etherBarage.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.attumentLess.prediction.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.attumentLess.gaze.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.attumentLess.glare.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.attumentLess.exhaustionStrike.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.attumentLess.taunt.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.attumentLess.sing.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.attumentLess.Disguise.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.attumentLess.rapidSlashes.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.attumentLess.CeaselessSlashes.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.attumentLess.MastersFlourish.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.attumentLess.Twincleave.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.attumentLess.ProminencDraw.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.attumentLess.FlashdrawStrike.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.attumentLess.PressureBlast.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.attumentLess.punishment.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.attumentLess.onslaught.info}
                onClick={handleMantraClick}
              />
            </ToggleContainer>
            <ToggleContainer
              title="Oathbound"
              className="Oathbound"
              sharedStyle={{ color: "SlateBlue" }}
            >
              <Mantra
                info={mantras.oathBound.arcSuit.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.oathBound.arcBeam.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.oathBound.arcWave.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.oathBound.mindsoothe.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.oathBound.tranquilCircle.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.oathBound.SightlessBeam.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.oathBound.Equalizer.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.oathBound.LordSlice.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.oathBound.Judgement.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.oathBound.RadiantKick.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.oathBound.RadiantDawn.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.oathBound.BlindingDawn.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.oathBound.PrecisionCuts.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.oathBound.CloseShave.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.oathBound.JetKick.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.oathBound.FakeStrike.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.oathBound.SymbioticSustain.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.oathBound.ParasiticLeech.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.oathBound.Ascension.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.oathBound.SinisterHalo.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.oathBound.CelestialAssault.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.oathBound.IllusoryCounter.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.oathBound.MirrorIllusion.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.oathBound.IllusoryServants.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.oathBound.restrain.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.oathBound.repture.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.oathBound.impel.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.oathBound.palmStrike.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.oathBound.decimate.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.oathBound.lethalInjection.info}
                onClick={handleMantraClick}
              />
            </ToggleContainer>
            <ToggleContainer
              title="MonsterMantras"
              className="MonsterMantras"
              sharedStyle={{ color: "darkcyan" }}
            >
              <Mantra
                info={mantras.monstertMantras.brachialSpear.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.monstertMantras.coralSpear.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.monstertMantras.dreadBreath.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.monstertMantras.beastBurrow.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.monstertMantras.enforcerPull.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.monstertMantras.mechaGatling.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.monstertMantras.whirlingBlade.info}
                onClick={handleMantraClick}
              />
              <Mantra
                info={mantras.monstertMantras.abyssalRidge.info}
                onClick={handleMantraClick}
              />
            </ToggleContainer>
          </div>
          <div className="leftBottomWindow">
            <img src={clanImage} alt="clan" />
          </div>
        </div>
        <div className="rightContainer">
          <div className="rightWindow">
            {selectedMantra ? (
              <div>
                <h2>{selectedMantra.mantraName}</h2>
                <p>
                  <strong>Leveling:</strong> {selectedMantra.leveling}
                </p>
                <h3>Modifiers:</h3>
                <ul>
                  {Object.entries(selectedMantra.modifiers).map(
                    ([key, value]) => (
                      <li key={key}>
                        {key}: {value ? "✅" : "❌"}
                      </li>
                    )
                  )}
                </ul>
                <h3>Effect:</h3>
                <ul>
                  {Object.entries(selectedMantra.effect).map(([key, value]) => (
                    <li key={key}>
                      {key}: {value}
                    </li>
                  ))}
                </ul>
                <h3>Sparks:</h3>
                <ul>
                  {Object.entries(selectedMantra.sparks).map(([key, value]) => (
                    <li key={key}>
                      {key}: {value ? "✅" : "❌"}
                    </li>
                  ))}
                </ul>
                <h3>Sparks Effect:</h3>
                <ul>
                  {Object.entries(selectedMantra.sparksEffect).map(
                    ([key, value]) => (
                      <li key={key}>
                        {key}: {value}
                      </li>
                    )
                  )}
                </ul>
              </div>
            ) : (
              <div>
                <h1>Deepwoken Modifier</h1>
                <p>Welcome to the Deepwoken Mod.</p>
                <p>Here you can:</p>
                <ul>
                  <li>
                    View any modifications of your mantras in a convenient
                    format.
                  </li>
                  <li>See the effect of your modifications.</li>
                  <li>Look at existing leveling effects.</li>
                </ul>
                <div>
                  <a
                    href="https://www.roblox.com/users/462691831/profile"
                    target="_blank"
                  >
                    <button>@sans_13377</button>
                  </a>
                </div>
              </div>
            )}
          </div>
          <div className="rightBottomWindow">
            <img
              src="https://media1.tenor.com/m/u7YMJ3Jv89sAAAAd/sharko-deepwoken-owl-thresher.gif"
              alt="gif"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
