const mantras = {
  flameCharm: {
    daunMantra: {},
    fireBlade: {
      info: {
        mantraName: "Fire Blade",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },

        leveling: "increases posture dmg by per level",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "?",
          eternalLogstone: "?",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "Makes it break more envionment",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: true,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark:
            "(only works on starkindered) убирает эффект старкинера делает обычный файрблейд",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    flameGrab: {
      info: {
        mantraName: "flame Grab",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "Makes the mantra travel further",
          perfectLens: "Makes the mantra travel further",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    flameRepulsion: {
      info: {
        mantraName: "flame Repulsion",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "Makes the repulsion larger",
          stratusStone: "Makes the repulsion larger",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: true,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "trow repulsion",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    flameRepulsion: {
      info: {
        mantraName: "flame Repulsion",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "Makes the repulsion larger",
          stratusStone: "Makes the repulsion larger",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: true,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "trow repulsion",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    burningServants: {
      info: {
        mantraName: "Burning Servants",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: true,
          rushShard: true,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },
        leveling: "This mantra has no leveling effect",
        effect: {
          cloudStone: "Increases servant radius.",
          stratusStone: "Increases servant radius.",
          driftShard: "Makes the mantra faster.",
          rushShard: "Makes the mantra faster.",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: true,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark:
            "Makes the servants aim outwards instead of inwards and ragdoll.",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    fireGun: {
      info: {
        mantraName: "Fire Gun",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: true,
          rushShard: true,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          driftShard: "Makes bullets go farther and faster.",
          rushShard: "Makes bullets go farther and faster.",
        },
        sparks: {
          blastSpark: true,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: true,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark:
            "Makes every 6 bullet hits proc an explosion but deal less damage on fire gun hits.",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark:
            "Makes bullets split into two (they shoot in random directions).",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    flameOfDenial: {
      info: {
        mantraName: "Flame of Denial",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },
        leveling: "Increases heal amount per proc per level.",
        effect: {
          vibrantGem: "??",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    flameBlind: {
      info: {
        mantraName: "Flame Blind",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          cloudStone: "??",
          stratusStone: "??",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    firePalm: {
      info: {
        mantraName: "Fire Palm★",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          cloudStone: "Makes the 'fire wave' larger.",
          stratusStone: "Makes the 'fire wave' larger.",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "Makes it break more environment.",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    fireEruption: {
      info: {
        mantraName: "Fire Eruption★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: true,
          magnifyingStone: true,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "Makes the 'eruption' larger.",
          magnifyingStone: "Makes the 'eruption' larger.",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    fireForge: {
      info: {
        mantraName: "Fire Forge★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: true,
          magnifyingStone: true,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "Makes the daggers go faster.",
          perfectLens: "Makes the daggers go faster.",
          aeonLogstone: "",
          eternalLogstone: "??",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: true,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark:
            "Do a flaming twirl before shooting fire knives in a straight line.",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    gracefulFlame: {
      info: {
        mantraName: "Graceful Flame★",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone:
            "Makes the 'Graceful Flames' last longer, but adds more ritual keys to the ritual cast.",
          eternalLogstone:
            "Makes the 'Graceful Flames' last longer, but adds more ritual keys to the ritual cast.",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem:
            "Makes the 'passive healing' effect on the graceful flame better.",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    flameSentinel: {
      info: {
        mantraName: "Flame Sentinel★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: true,
          magnifyingStone: true,
          vibrantGem: true,
          amnesicDriftwood: true,
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "?",
          perfectLens: "?",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: true,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "The orb now follows you.",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    flameWisp: {
      info: {
        mantraName: "Flame Wisp★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling:
          "Increases healing given by flame wisp by (PLACEHOLDER) per level.",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone:
            "Makes the wisp last longer, but adds more ritual keys to the ritual cast.",
          eternalLogstone:
            "Makes the wisp last longer, but adds more ritual keys to the ritual cast.",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    flameLeap: {
      info: {
        mantraName: "Flame Leap★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: true,
          magnifyingStone: true,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "makes hitbox bigger",
          magnifyingStone: "makes hitbox bigger",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: true,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark:
            "Removes most of the vertical movement, instead, you will flip forward before slamming down, sending anyone hit into the sky.",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    flareVolley: {
      info: {
        mantraName: "Flare Volley★★",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: true,
          rushShard: true,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          cloudStone: "?",
          stratusStone: "?",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "?",
          rushShard: "?",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    flameAssault: {
      info: {
        mantraName: "Flame Assault★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: true,
          magnifyingStone: true,
          vibrantGem: true,
          amnesicDriftwood: true,
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "Makes the Fire Assault go further.",
          perfectLens: "Makes the Fire Assault go further.",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone:
            "Makes the hitbox of Fire Assault larger and possibly makes it go further but needs testing.",
          magnifyingStone:
            "Makes the hitbox of Fire Assault larger and possibly makes it go further but needs testing.",
          vibrantGem: "?",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    risingFlame: {
      info: {
        mantraName: "Rising Flame★★",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          cloudStone: "Makes the hitbox of Rising Flame larger.",
          stratusStone: "Makes the hitbox of Rising Flame larger.",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    relentlessFlames: {
      info: {
        mantraName: "Relentless Flames★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "???",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    ashSlam: {
      info: {
        mantraName: "Ash Slam★★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "Makes the hitbox bigger.",
          perfectLens: "Makes the hitbox bigger.",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "???",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    flameBallista: {
      info: {
        mantraName: "Flame Ballista★★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: true,
          magnifyingStone: true,
          vibrantGem: true,
          amnesicDriftwood: true,
        },
        leveling: "",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "Increases range/speed.",
          perfectLens: "Increases range/speed.",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "Makes the 'eruption' larger.",
          magnifyingStone: "Makes the 'eruption' larger.",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: true,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "Fly in the air while charging the ballista.",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    flamingScourge: {
      info: {
        mantraName: "Flaming Scourge★★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },
        leveling: "",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "?",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: true,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "Turns the move into a whip that pulls people to you",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
  },
  thunderCall: {
    LightningBlade: {
      info: {
        mantraName: "Lightning Blade",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "Increases the hitbox of the blades.",
          stratusStone: "Increases the hitbox of the blades.",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "Increases the length of the applied 'shock' effect.",
          eternalLogstone:
            "Increases the length of the applied 'shock' effect.",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: true,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "Adds a third slash after the initial two slashes.",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    JoltGrab: {
      info: {
        mantraName: "Jolt Grab",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "Increases the range of Jolt Grab.",
          perfectLens: "Increases the range of Jolt Grab.",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "Unknown.",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: true,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark:
            "Teleports to the opponent, removes horizontal movement, and adds AOE damage.",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    LightningBeam: {
      info: {
        mantraName: "Lightning Beam",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    LightningStrike: {
      info: {
        mantraName: "Lightning Strike★",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: true,
          rushShard: true,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "Slightly increases hitbox size.",
          stratusStone: "Slightly increases hitbox size.",
          crystalLens: "Extends the range of the strikes.",
          perfectLens: "Extends the range of the strikes.",
          aeonLogstone:
            "Increases the number of strikes but reduces damage significantly.",
          eternalLogstone:
            "Increases the number of strikes but reduces damage significantly.",
          driftShard: "No visible effect.",
          rushShard: "No visible effect.",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    FleetingSparks: {
      info: {
        mantraName: "Fleeting Sparks★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: true,
          magnifyingStone: true,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "Increases the range the orbs will lock onto someone.",
          perfectLens: "Increases the range the orbs will lock onto someone.",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "increases impact explosion size",
          magnifyingStone: "increases impact explosion size",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: true,
          multiplyingSpark: true,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark:
            "Doubles the orb amount; orb damage is split between all.",
          multiplyingSpark:
            "Removes laser function; the orbs now ignore all obstacles defending the target.",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    LightningStream: {
      info: {
        mantraName: "Lightning Stream★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "Increases range and speed of the stream.",
          perfectLens: "Increases range and speed of the stream.",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: true,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: true,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark:
            "Removes the pulling effect, does damage, and applies shock to the opponent.",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "Pulls you towards your opponent instead.",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    LightningImpact: {
      info: {
        mantraName: "Lightning Impact★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "?",
          perfectLens: "?",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: true,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark:
            "Teleports behind the target upon striking, increases wind-up, and moves forward slightly when missing.",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    LightningClones: {
      info: {
        mantraName: "Lightning Clones★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "Makes clones last longer.",
          eternalLogstone: "Makes clones last longer.",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: true,
          magnetSpark: false,
          multiplyingSpark: true,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "Creates an AOE explosion when clones disappear.",
          magnetSpark: "",
          multiplyingSpark: "Spawns two clones instead of one.",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    ElectroCarve: {
      info: {
        mantraName: "Electro Carve★",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },

        leveling: "",

        effect: {
          cloudStone: "Enlarges hitbox of the electric sphere.",
          stratusStone: "Enlarges hitbox of the electric sphere.",
          crystalLens: "Increases range and speed of the carve.",
          perfectLens: "Increases range and speed of the carve.",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: true,
          magnetSpark: true,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "Slams the ground, creating a shocking field.",
          magnetSpark: "Throws a javelin that creates a shocking field.",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    ThunderKick: {
      info: {
        mantraName: "Thunder Kick★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },

        leveling: "Increases posture damage by (PLACEHOLDER) per level",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "Moves you in the direction of the kick.",
          perfectLens: "Moves you in the direction of the kick.",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    BoltPiercer: {
      info: {
        mantraName: "Bolt Piercer★★",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: true,
          rushShard: true,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },

        leveling: "",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "Gives your piercer more range.",
          perfectLens: "Gives your piercer more range.",
          aeonLogstone: "increases bolt amount",
          eternalLogstone: "increases bolt amount",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    GrandJavelin: {
      info: {
        mantraName: "Grand Javelin★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "Increases damage by (PLACEHOLDER) per level",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: true,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark:
            "Turns the javelin into a triple shot javelin that shoots in a triangle formation in front of you.",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    LightningAssault: {
      info: {
        mantraName: "Lightning Assault★★",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          cloudStone: "Increases the amount of teleports.",
          stratusStone: "Increases the amount of teleports.",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "?",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    ThunderWisp: {
      info: {
        mantraName: "Thunder Wisp★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone:
            "Makes the wisp last longer but adds more ritual keys to the ritual cast.",
          eternalLogstone:
            "Makes the wisp last longer but adds more ritual keys to the ritual cast.",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    StormBlades: {
      info: {
        mantraName: "Storm Blades★★",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          cloudStone: "Increases size of AOE (default).",
          stratusStone: "Increases size of AOE (default).",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "?",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: true,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark:
            "Changes the mantra to the 'Storm Cage', creating a large circle around the user, preventing others from leaving the circle, shocking them and pulling them back if they do. This disables other modifications.",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    LightningCloak: {
      info: {
        mantraName: "Lightning Cloak★★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "Does absolutely nothing.",
          eternalLogstone: "Does absolutely nothing.",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    RisingThunder: {
      info: {
        mantraName: "Rising Thunder★★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "?",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    EmotionWave: {
      info: {
        mantraName: "Emotion Wave★★★",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          cloudStone:
            "Increases size, amount of lightning strikes, and makes them spawn one after another.",
          stratusStone:
            "Increases size, amount of lightning strikes, and makes them spawn one after another.",
          crystalLens: "?",
          perfectLens: "?",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    sparksSwap: {
      info: {
        mantraName: "sparks Swap★★★",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          cloudStone: "Possibly increases range, needs testing.",
          stratusStone: "Possibly increases range, needs testing.",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "?",
          eternalLogstone: "?",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: true,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark:
            "Significantly reduces cooldown and range, also gives an audio cue for when you swap. BOOGIE WOOGIE!",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
  },
  frostDraw: {
    FrostGrab: {
      info: {
        mantraName: "Frost Grab★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "Increases chill duration",
          eternalLogstone: "Increases chill duration",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem:
            "(only works with blast spark) Makes the ice before teleport bigger.",
        },

        sparks: {
          blastSpark: true,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark:
            "increases grab length and summons 2 icicles to implae your foe, also summons ice at users feet, removes shill",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    IceChain: {
      info: {
        mantraName: "Ice Chains★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: true,
          glassStone: true,
          magnifyingStone: true,
          vibrantGem: true,
          amnesicDriftwood: true, // всегда TRUE
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "Delays the chains firing.",
          eternalLogstone: "Delays the chains firing.",
          driftShard: "",
          rushShard: "",
          glassStone: "?",
          magnifyingStone: "?",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    IceLance: {
      info: {
        mantraName: "Ice Lance★★",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          cloudStone: "?.",
          stratusStone: "?.",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "Supposedly makes the chill last longer.",
          eternalLogstone: "Supposedly makes the chill last longer.",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    IceBeam: {
      info: {
        mantraName: "Ice Beam",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          cloudStone: "Makes the beam and its hitbox larger.",
          stratusStone: "Makes the beam and its hitbox larger.",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "?",
          eternalLogstone: "?",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    IceBlade: {
      info: {
        mantraName: "Ice Blade★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "?.",
          perfectLens: "?.",
          aeonLogstone: "?",
          eternalLogstone: "?",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "?",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    iceSpikes: {
      info: {
        mantraName: "Ice Spikes",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "",
        effect: {
          cloudStone: "Makes ice spikes and their hitbox larger.",
          stratusStone: "Makes ice spikes and their hitbox larger.",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    frozenServants: {
      info: {
        mantraName: "Frozen Servants",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: true,
          rushShard: true,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },
        leveling: "",
        effect: {
          cloudStone: "Makes the servant radius larger.",
          stratusStone: "Makes the servant radius larger.",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "Makes the mantra faster.",
          rushShard: "Makes the mantra faster.",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: true,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: true,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark:
            "Gives you one BIG BOY instead of a bunch of mini servants.",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "Makes the servants aim outwards instead of inwards.",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    wardensBlades: {
      info: {
        mantraName: "Warden's Blades",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: true,
          rushShard: true,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "",
        effect: {
          cloudStone: "Makes the blades and the radius larger.",
          stratusStone: "Makes the blades and the radius larger.",
          crystalLens: "?",
          perfectLens: "?",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "?",
          rushShard: "?",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    iceDaggers: {
      info: {
        mantraName: "Ice Daggers★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "Increases range of which you can fire ice daggers.",
          perfectLens: "Increases range of which you can fire ice daggers.",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    iceEruption: {
      info: {
        mantraName: "Ice Eruption★",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },
        leveling: "",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: true,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark:
            "Removes the 'hitbox' and damage from the mantra, causing an ice eruption that creates a tall, large circular ice wall around the user.",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    iceForge: {
      info: {
        mantraName: "Ice Forge★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: true,
          rushShard: true,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "",
        effect: {
          blastSpark: "Causes the shuriken projectiles to explode upon impact.",
          magnetSpark: "If shurikens hit, teleport and aerial kick the victim.",
          driftShard: "Makes the shurikens travel faster.",
          rushShard: "Makes the shurikens travel faster.",
        },
        sparks: {
          blastSpark: true,
          magnetSpark: true,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "Causes the shuriken projectiles to explode upon impact.",
          magnetSpark: "If shurikens hit, teleport and aerial kick the victim.",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    iceLaser: {
      info: {
        mantraName: "Ice Laser★★",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },
        leveling: "",
        effect: {
          cloudStone: "shoot more bullet +1",
          stratusStone: "shoot more bullet +1",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: true,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark:
            "when you shoot the ice laser projectile, the projectile stays exactly where you shot it for a second and then it shoots",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    Iceberg: {
      info: {
        mantraName: "Iceberg★★★",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: true,
          rushShard: true,
          glassStone: true,
          magnifyingStone: true,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "Increases the healing from the 'Iceberg'.",
          rushShard: "Increases the healing from the 'Iceberg'.",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    GlacialArc: {
      info: {
        mantraName: "Glacial Arc★",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: true,
          magnifyingStone: true,
          vibrantGem: true,
          amnesicDriftwood: true,
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          cloudStone: "Makes the Glacial Arc wider.",
          stratusStone: "Makes the Glacial Arc wider.",
          crystalLens: "Makes the Glacial Arc longer.",
          perfectLens: "Makes the Glacial Arc longer.",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone:
            "Adds a small explosion to the end of the Glacial Arc. The more stones, the larger the explosion.",
          magnifyingStone:
            "Adds a small explosion to the end of the Glacial Arc. The more stones, the larger the explosion.",
          vibrantGem:
            "Adds a small explosion to the end of the Glacial Arc. The more stones, the larger the explosion.",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    FrostWisp: {
      info: {
        mantraName: "Frost Wisp★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling:
          "Increases the size of the ice placed on the floor per level.",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone:
            "Makes the wisp last longer, but adds more ritual keys to the ritual cast.",
          eternalLogstone:
            "Makes the wisp last longer, but adds more ritual keys to the ritual cast.",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    IceSkate: {
      info: {
        mantraName: "Ice Skates★★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "Slows down the descension of the ice skates per level.",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    IceCubes: {
      info: {
        mantraName: "Ice Cubes★★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: true,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark:
            "The Ice Cubes will shoot one by one instead of all shooting at once.",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    IceCarve: {
      info: {
        mantraName: "Ice Carve★★",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: true,
          rushShard: true,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          cloudStone: "Makes the carve bigger.",
          stratusStone: "Makes the carve bigger.",
          crystalLens: "Increases the range of the carve.",
          perfectLens: "Increases the range of the carve.",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: true,
          springSpark: true,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "Makes the carve sharper and more precise.",
          springSpark:
            "throws the carving as a projectile which returns to you .",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    IceFlock: {
      info: {
        mantraName: "Ice Flock★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    RisingFrost: {
      info: {
        mantraName: "Rising Frost★★",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          cloudStone: "Makes the Glaive bigger.",
          stratusStone: "Makes the Glaive bigger.",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    IceSmash: {
      info: {
        mantraName: "Ice Smash★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "Increases posture damage by (PLACEHOLDER) per level.",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    crystalKnee: {
      info: {
        mantraName: "Crystal Knee★★",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          cloudStone: "?",
          stratusStone: "?",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "?",
          eternalLogstone: "?",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: true,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark:
            "Appears to change the mantra to a spining cartwheel kick instead of a backflip kick which kicks opponents away",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    crystaImpale: {
      info: {
        mantraName: "Crystal Impale★★",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          cloudStone: "?",
          stratusStone: "?",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "?",
          eternalLogstone: "?",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: true,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "?",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    iceFissure: {
      info: {
        mantraName: "Ice Fissure★★★",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          cloudStone: "?",
          stratusStone: "?",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "?",
          eternalLogstone: "?",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: true,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "?",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
  },
  galeBreath: {
    WindBlade: {
      info: {
        mantraName: "Wind Blade",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: true,
          rushShard: true,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "Default damage changes: 25.5, 29, 32.5, 35.5, 39",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "?",
          eternalLogstone: "?",
          driftShard: "Makes the blade go faster and farther",
          rushShard: "Makes the blade go faster and farther",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    TornadoKick: {
      info: {
        mantraName: "Tornado Kick",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: true,
          rushShard: true,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "?",
          eternalLogstone: "?",
          driftShard: "?",
          rushShard: "?",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    AirForce: {
      info: {
        mantraName: "Air Force",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },

        leveling: "No specific leveling information provided.",

        effect: {
          cloudStone: "Makes the blast larger",
          stratusStone: "Makes the blast larger",
          crystalLens: "Makes the blast longer",
          perfectLens: "Makes the blast longer",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "?",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: true,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark:
            "instead of pushing people away, it pulls people to you if it hits",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    GaleLunge: {
      info: {
        mantraName: "Gale Lunge★",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "Increases damage by (PLACEHOLDER) every level.",

        effect: {
          cloudStone: "?",
          stratusStone: "?",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "?",
          eternalLogstone: "?",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    TornadoW: {
      info: {
        mantraName: "Tornado★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "", // No leveling effect

        effect: {
          cloudStone: "?",
          stratusStone: "?",
          crystalLens: "?",
          perfectLens: "?",
          aeonLogstone: "?",
          eternalLogstone: "?",
          driftShard: "?",
          rushShard: "?",
          glassStone: "?",
          magnifyingStone: "?",
          vibrantGem: "?",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    GaleLunge: {
      info: {
        mantraName: "Gale Lunge★",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "Increases damage by (PLACEHOLDER) every level.",

        effect: {
          cloudStone: "?",
          stratusStone: "?",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "?",
          eternalLogstone: "?",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    heavenlyWind: {
      info: {
        mantraName: "Heavenly Wind★",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: true,
          rushShard: true,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "", // No leveling effect

        effect: {
          cloudStone: "?",
          stratusStone: "?",
          crystalLens: "?",
          perfectLens: "?",
          aeonLogstone: "?",
          eternalLogstone: "?",
          driftShard: "?",
          rushShard: "?",
          glassStone: "?",
          magnifyingStone: "?",
          vibrantGem: "?",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    galetrap: {
      info: {
        mantraName: "Galetrap★",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: true,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },

        leveling: "", // No leveling effect

        effect: {
          cloudStone: "?",
          stratusStone: "?",
          crystalLens: "?",
          perfectLens: "?",
          aeonLogstone: "?",
          eternalLogstone: "?",
          driftShard: "?",
          rushShard: "?",
          glassStone: "?",
          magnifyingStone: "?",
          vibrantGem: "?",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    galePunch: {
      info: {
        mantraName: "Gale Punch★",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: true,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },

        leveling: "Inc damage.", // Increases damage

        effect: {
          cloudStone: "?",
          stratusStone: "?",
          crystalLens: "?",
          perfectLens: "?",
          aeonLogstone: "?",
          eternalLogstone: "?",
          driftShard: "?",
          rushShard: "?",
          glassStone: "?",
          magnifyingStone: "?",
          vibrantGem: "?",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    windForge: {
      info: {
        mantraName: "Wind Forge★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "Increases damage by (PLACEHOLDER) every level.", // Increases damage by a fixed amount

        effect: {
          cloudStone: "?",
          stratusStone: "?",
          crystalLens:
            "Increases range to begin the wind forge (+ distance of lockon)",
          perfectLens: "?",
          aeonLogstone: "?",
          eternalLogstone: "?",
          driftShard: "?",
          rushShard: "?",
          glassStone: "?",
          magnifyingStone: "?",
          vibrantGem: "?",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: true,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark:
            "Splits the wind forges into multiple lock-on projectiles",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    championsW: {
      info: {
        mantraName: "Champions Whirlthrow★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },

        leveling: "", // No leveling effect

        effect: {
          cloudStone: "?",
          stratusStone: "?",
          crystalLens: "?",
          perfectLens: "?",
          aeonLogstone: "?",
          eternalLogstone: "?",
          driftShard: "?",
          rushShard: "?",
          glassStone: "?",
          magnifyingStone: "?",
          vibrantGem: "?",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    twisterKicks: {
      info: {
        mantraName: "Twister Kicks★",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "", // No leveling effect

        effect: {
          cloudStone: "?",
          stratusStone: "?",
          crystalLens: "?",
          perfectLens: "?",
          aeonLogstone: "?",
          eternalLogstone: "?",
          driftShard: "?",
          rushShard: "?",
          glassStone: "?",
          magnifyingStone: "?",
          vibrantGem: "?",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    risingWind: {
      info: {
        mantraName: "Rising Wind★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "?",
          stratusStone: "?",
          crystalLens:
            "dash forward and cast rising wind, lens added seem to increase distance of dash",
          perfectLens:
            "dash forward and cast rising wind, lens added seem to increase distance of dash",
          aeonLogstone: "?",
          eternalLogstone: "?",
          driftShard: "?",
          rushShard: "?",
          glassStone: "?",
          magnifyingStone: "?",
          vibrantGem: "?",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    windGun: {
      info: {
        mantraName: "Wind Gun★★",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: true,
          rushShard: true,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "?",
          stratusStone: "?",
          crystalLens: "?",
          perfectLens: "?",
          aeonLogstone: "?",
          eternalLogstone: "?",
          driftShard: "?",
          rushShard: "?",
          glassStone: "?",
          magnifyingStone: "?",
          vibrantGem: "?",
        },

        sparks: {
          blastSpark: true,
          magnetSpark: false,
          multiplyingSpark: true,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark:
            "Turns the Wind Gun into a 'wind shotgun', shorter ranged but a lot more bullets.",
          magnetSpark: "",
          multiplyingSpark:
            "Turns the Wind Gun into 4 slightly spread long-range bullets.",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    galeWisp: {
      info: {
        mantraName: "Gale Wisp★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone:
            "Makes the wisp last longer but adds more ritual keys to the ritual cast.",
          eternalLogstone:
            "Makes the wisp last longer but adds more ritual keys to the ritual cast.",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    windCarve: {
      info: {
        mantraName: "Wind Carve★★",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "Makes the 'Wind Carve sphere' larger.",
          stratusStone: "Makes the 'Wind Carve sphere' larger.",
          crystalLens:
            "Increase the distance of the ball of wind on wind carve.",
          perfectLens:
            "Increase the distance of the ball of wind on wind carve.",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "?",
          rushShard: "?",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "?",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    astralWind: {
      info: {
        mantraName: "Astral Wind★★★",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },

        leveling: "Inc damage.",

        effect: {
          cloudStone: "Makes the Astral Wind 'star' come out slower.",
          stratusStone: "Makes the Astral Wind 'star' come out slower.",
          crystalLens: "?",
          perfectLens: "?",
          aeonLogstone: "?",
          eternalLogstone: "?",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "?",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    pillarsOfErisia: {
      info: {
        mantraName: "Pillars of Erisia★★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          crystalLens: "?",
          perfectLens: "?",
          cloudStone: "",
          stratusStone: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    windPassage: {
      info: {
        mantraName: "Wind Passage★★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: true,
          magnifyingStone: true,
          vibrantGem: true,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "Makes the Wind Passage longer.",
          perfectLens: "Makes the Wind Passage longer.",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "?",
          magnifyingStone: "?",
          vibrantGem: "?",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
  },
  shadowCast: {
    darkBlade: {
      info: {
        mantraName: "Dark Blade",
        modifiers: {
          cloudStone: true,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true, // always TRUE
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          cloudStone: "?",
          stratusStone: "?",
          crystalLens: "?",
          perfectLens: "?",
          aeonLogstone: "?",
          eternalLogstone: "?",
          driftShard: "?",
          rushShard: "?",
          glassStone: "?",
          magnifyingStone: "?",
          vibrantGem: "?",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: true,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "Adds a spinning hit at the end of the mantra.",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    shadowGun: {
      info: {
        mantraName: "Shadow Gun",
        modifiers: {
          cloudStone: true,
          stratusStone: false,
          crystalLens: true,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },
        leveling:
          "Increases stance damage per level until defense is broken at level 5 (non-explosive version).",
        effect: {
          cloudStone: "Increases the width of the Shadow Gun.",
          stratusStone: "Increases the width of the Shadow Gun.",
          crystalLens: "Increases the length of the Shadow Gun.",
          perfectLens: "Increases the length of the Shadow Gun.",
          aeonLogstone: "?",
          eternalLogstone: "?",
          driftShard: "?",
          rushShard: "?",
          glassStone: "?",
          magnifyingStone: "?",
          vibrantGem: "?",
        },
        sparks: {
          blastSpark: true,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark:
            "Turns a single shot into multiple shots, dealing damage multiple times.",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    clutchingShadow: {
      info: {
        mantraName: "Clutching Shadow",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling:
          "Increases posture by 6% per level, with the max level increasing it by only 4%.",
        effect: {
          cloudStone: "?",
          stratusStone: "?",
          crystalLens: "?",
          perfectLens: "?",
          aeonLogstone: "?",
          eternalLogstone: "?",
          driftShard: "?",
          rushShard: "?",
          glassStone: "?",
          magnifyingStone: "?",
          vibrantGem: "?",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    shadowChains: {
      info: {
        mantraName: "Shadow Chains★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "Level 5 adds an additional shadow chain.",
        effect: {
          cloudStone: "?",
          stratusStone: "?",
          crystalLens: "?",
          perfectLens: "?",
          aeonLogstone:
            "Increases the slow effect of the chains on the target.",
          eternalLogstone:
            "Increases the slow effect of the chains on the target.",
          driftShard: "?",
          rushShard: "?",
          glassStone: "?",
          magnifyingStone: "?",
          vibrantGem: "?",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    encircle: {
      info: {
        mantraName: "Encircle★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },
        leveling: "increases posture by roughly 6% per level.",
        effect: {
          cloudStone: "?",
          stratusStone: "?",
          crystalLens: "?",
          perfectLens: "?",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "?",
          rushShard: "?",
          glassStone: "?",
          magnifyingStone: "?",
          vibrantGem: "?",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    shadowEruption: {
      info: {
        mantraName: "Shadow Eruption★",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          cloudStone: "Increases the size of the eruption.",
          stratusStone: "Increases the size of the eruption.",
          crystalLens: "?",
          perfectLens: "?",
          aeonLogstone: "?",
          eternalLogstone: "?",
          driftShard: "?",
          rushShard: "?",
          glassStone: "?",
          magnifyingStone: "?",
          vibrantGem: "?",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    shadowSeekers: {
      info: {
        mantraName: "Shadow Seekers★",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          cloudStone: "Adds more shadow seeker orbs.",
          stratusStone: "Adds more shadow seeker orbs.",
          crystalLens: "?",
          perfectLens: "?",
          aeonLogstone: "?",
          eternalLogstone: "?",
          driftShard: "?",
          rushShard: "?",
          glassStone: "?",
          magnifyingStone: "?",
          vibrantGem: "?",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    shadeDevour: {
      info: {
        mantraName: "Shade Devour★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: true,
          rushShard: true,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true, // всегда TRUE
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "increases duration of buff",
          eternalLogstone: "",
          driftShard: "decreases windup of shade devour",
          rushShard: "decreases windup of shade devour",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "increases damage buff",
        },

        sparks: {
          blastSpark: true,
          magnetSpark: false,
          multiplyingSpark: true,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark:
            "Blast radius increases drastically, also guardbreaks.(blast and multiplying: The 3blasts from multiplying dramatically gain size, and the blasts guardbreak)",
          magnetSpark: "",
          multiplyingSpark:
            "Blasts increase from 1 to 3, with the third knocking your opponent back.",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    shadowRoar: {
      info: {
        mantraName: "Shadow Roar★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true, // всегда TRUE
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "?",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "?",
          rushShard: "?",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "?",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    risingShadow: {
      info: {
        mantraName: "Rising Shadow★★",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: true,
          rushShard: true,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true, // всегда TRUE
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "Makes the tendril lock on farther.",
          stratusStone: "Makes the tendril lock on farther.",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "Makes the 'Rising Shadow tendril' faster.",
          rushShard: "Makes the 'Rising Shadow tendril' faster.",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: true,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark:
            "The 'Rising Shadow tendril' will no longer make opponents rise up, but instead, will keep the tendril on them. If the opponent with the tendril on them tries to cast a mantra, a portion of ether will be returned to you.",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    shadowMeteor: {
      info: {
        mantraName: "Shadow Meteor★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true, // всегда TRUE
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "?",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "?",
          rushShard: "?",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "?",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    devouringEye: {
      info: {
        mantraName: "Devouring Eye★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: true,
          magnifyingStone: true,
          vibrantGem: false,
          amnesicDriftwood: true, // всегда TRUE
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "Increases range of the Eye.",
          magnifyingStone: "Increases range of the Eye.",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    shadeStep: {
      info: {
        mantraName: "Shade Step★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true, // всегда TRUE
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    shadeWisp: {
      info: {
        mantraName: "Shade Wisp★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true, // всегда TRUE
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone:
            "Makes the wisp last longer but adds more ritual keys to the ritual cast.",
          eternalLogstone:
            "Makes the wisp last longer but adds more ritual keys to the ritual cast.",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    shadowAssault: {
      info: {
        mantraName: "Shadow Assault★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: true,
          magnifyingStone: true,
          vibrantGem: true,
          amnesicDriftwood: true, // всегда TRUE
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "Makes the Shadow Assault go further.",
          perfectLens: "Makes the Shadow Assault go further.",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone:
            "Makes the hitbox of Shadow Assault larger and possibly makes it go further but needs testing.",
          magnifyingStone:
            "Makes the hitbox of Shadow Assault larger and possibly makes it go further but needs testing.",
          vibrantGem: "?",
        },

        sparks: {
          blastSpark: true,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark:
            "Unleashes a rapid flurry of attacks on those hit if your HP is below 40%.",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    eclipseKick: {
      info: {
        mantraName: "Eclipse Kick★★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true, // всегда TRUE
        },

        leveling: "Increases posture damage by (PLACEHOLDER) per level.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: true,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark:
            "Eclipse Kick's windup will be a lot faster, and the animation changes. It will now perform a kick that dazes even if blocked or dodged. If this modified Eclipse Kick lands, you can cast it again to perform the regular Eclipse Kick.",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    shadeBringer: {
      info: {
        mantraName: "Shade Bringer★★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: true,
          rushShard: true,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true, // всегда TRUE
        },

        leveling:
          "Increases stance break speed per level, especially from area-based actions.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: true,
          reversalSpark: false,
          roundSpark: false,
          springSpark: true,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark:
            "Deals more damage in more combo stacks and shield replenishment is larger, increased damage scaling.",
          reversalSpark: "",
          roundSpark: "",
          springSpark:
            "Deals massive damage to shields. spring and multiplying makes you do the flip attack first then the two slashes",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    shadowVortex: {
      info: {
        mantraName: "Shadow Vortex★★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: true,
          magnifyingStone: true,
          vibrantGem: true,
          amnesicDriftwood: true, // всегда TRUE
        },

        leveling: "damage changes: 11.5, 13.25, 15, 16.75, 18.5",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "increases size greatly",
          magnifyingStone: "increases size greatly",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
  },
  ironSing: {
    metalEruption: {
      info: {
        mantraName: "Metal Eruption",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true, // всегда TRUE
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "Makes the 'eruption' larger.",
          stratusStone: "Makes the 'eruption' larger.",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "?",
          eternalLogstone: "?",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "?",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    needleBarrage: {
      info: {
        mantraName: "Needle Barrage",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling:
          "Increases amount of rods thrown per level up to 6 rods thrown.",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: true,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark:
            "Shoots more needles but removes the jump. Keeps your momentum and shoots only in front of you, increasing windup.",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    metalRampart: {
      info: {
        mantraName: "Metal Rampart",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          cloudStone: "?",
          stratusStone: "?",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "?",
          eternalLogstone: "?",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    metalKick: {
      info: {
        mantraName: "Metal Kick★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: true,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark:
            "Metal Kick becomes a faster dropkick but removes the knockdown effect.",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    metalTurret: {
      info: {
        mantraName: "Metal Turret★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: true,
          rushShard: true,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "increases duration of turret",
          eternalLogstone: "",
          driftShard: "speeds up the turrets spin",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: true,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark:
            "splits the turret into three turrets, keeps base functionality for all three",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    ironSkin: {
      info: {
        mantraName: "Iron Skin★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "Makes Iron Skin last longer.",
          eternalLogstone: "Makes Iron Skin last longer.",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: true,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "?",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    ironQuills: {
      info: {
        mantraName: "iron Quills★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "increases the range of the quills",
          perfectLens: "increases the range of the quills",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    chainPull: {
      info: {
        mantraName: "Chain Pull★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "?",
          perfectLens: "?",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "?",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: true,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark:
            "Makes the 'chain' of Chain Pull push opponents away instead of pulling them towards you.",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    metalRain: {
      info: {
        mantraName: "Metal Rain★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "This mantra has no leveling effect.",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "?",
          perfectLens: "?",
          aeonLogstone: "?",
          eternalLogstone: "?",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    caltrops: {
      info: {
        mantraName: "Caltrops★★",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "", // This mantra has no leveling effect.

        effect: {
          cloudStone: "Does nothing.",
          stratusStone: "Does nothing.",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "?",
          eternalLogstone: "?",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    firingLine: {
      info: {
        mantraName: "Firing Line★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: true,
          rushShard: true,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect", // This mantra has no leveling effect.

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: true,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark:
            "summons two cannons and shoots two cannonballs at designated target",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    oxidizingRush: {
      info: {
        mantraName: "Oxidizing Rush★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "", // This mantra has no leveling effect.

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "?",
          perfectLens: "?",
          aeonLogstone: "?",
          eternalLogstone: "?",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    metalFake: {
      info: {
        mantraName: "Metal Fakeout★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.", // This mantra has no leveling effect.

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    metalBall: {
      info: {
        mantraName: "Metal Ball★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "", // This mantra has no leveling effect.

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "?",
          eternalLogstone: "?",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    MetalWisp: {
      info: {
        mantraName: "Metal Wisp★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true, // всегда TRUE
        },

        leveling: "", // This mantra has no leveling effect.

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone:
            "Makes the wisp last longer but adds more ritual keys to the ritual cast.",
          eternalLogstone:
            "Makes the wisp last longer but adds more ritual keys to the ritual cast.",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    MetalArmament: {
      info: {
        mantraName: "Metal Armament★★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true, // всегда TRUE
        },

        leveling: "", // This mantra has no leveling effect.

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    MetalGatling: {
      info: {
        mantraName: "Metal Gatling★★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true, // всегда TRUE
        },

        leveling: "", // This mantra has no leveling effect.

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    IronSlam: {
      info: {
        mantraName: "Iron Slam★★★",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true, // всегда TRUE
        },

        leveling: "", // This mantra has no leveling effect.

        effect: {
          cloudStone: "Increases the slam hitbox.",
          stratusStone: "Increases the slam hitbox.",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    IronHug: {
      info: {
        mantraName: "Iron Hug★★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true, // всегда TRUE
        },

        leveling: "", // This mantra has no leveling effect.

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem:
            "Talent Metal Absorption: Anyone hit by Iron Hug has their armour drained.",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    RocketLance: {
      info: {
        mantraName: "Rocket Lance★★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true, // всегда TRUE
        },

        leveling: "", // This mantra has no leveling effect.

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
  },
  bloodRend: {
    Crucifixion: {
      info: {
        mantraName: "Crucifixion",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: true,
          magnifyingStone: true,
          vibrantGem: true,
          amnesicDriftwood: true, // всегда TRUE
        },

        leveling: "This mantra has no leveling effect", // This mantra has no leveling effect.

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "?",
          magnifyingStone: "?",
          vibrantGem: "?",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: true,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "?",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    scarletCyclone: {
      info: {
        mantraName: "Scarlet Cyclone",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: true,
          magnifyingStone: true,
          vibrantGem: true,
          amnesicDriftwood: true, // всегда TRUE
        },

        leveling: "This mantra has no leveling effect", // This mantra has no leveling effect.

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "?",
          magnifyingStone: "?",
          vibrantGem: "?",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: true,
          reversalSpark: true,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "splits cyclone into three",
          reversalSpark: "Causes the cyclone to have a boomerang effect",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    bloodOrb: {
      info: {
        mantraName: "Blood Orb",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: true,
          magnifyingStone: true,
          vibrantGem: true,
          amnesicDriftwood: true, // всегда TRUE
        },

        leveling: "This mantra has no leveling effect", // This mantra has no leveling effect.

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "?",
          magnifyingStone: "?",
          vibrantGem: "?",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: true,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "spawns three orbs instead of one",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    BloodStakes: {
      info: {
        mantraName: "Blood Stakes★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: true,
          magnifyingStone: true,
          vibrantGem: true,
          amnesicDriftwood: true, // всегда TRUE
        },

        leveling: "This mantra has no leveling effect", // This mantra has no leveling effect.

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "?",
          magnifyingStone: "?",
          vibrantGem: "?",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    SanguineDive: {
      info: {
        mantraName: "Sanguine Dive★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: true,
          magnifyingStone: true,
          vibrantGem: true,
          amnesicDriftwood: true, // всегда TRUE
        },

        leveling: "This mantra has no leveling effect", // This mantra has no leveling effect.

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "?",
          magnifyingStone: "?",
          vibrantGem: "?",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    viciousDescent: {
      info: {
        mantraName: "Vicious Descent★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: true,
          magnifyingStone: true,
          vibrantGem: true,
          amnesicDriftwood: true, // всегда TRUE
        },

        leveling: "This mantra has no leveling effect", // This mantra has no leveling effect.

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "?",
          magnifyingStone: "?",
          vibrantGem: "?",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    Bloodedge: {
      info: {
        mantraName: "Bloodedge★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: true,
          magnifyingStone: true,
          vibrantGem: true,
          amnesicDriftwood: true, // всегда TRUE
        },

        leveling: "This mantra has no leveling effect", // This mantra has no leveling effect.

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "?",
          magnifyingStone: "?",
          vibrantGem: "?",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    BloodWisp: {
      info: {
        mantraName: "Blood Wisp★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true, // всегда TRUE
        },

        leveling: "This mantra has no leveling effect", // This mantra has no leveling effect.

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone:
            "Makes the wisp last longer, but adds more ritual keys to the ritual cast.",
          eternalLogstone:
            "Makes the wisp last longer, but adds more ritual keys to the ritual cast.",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    soulflareSiphon: {
      info: {
        mantraName: "Soulflare Siphon★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: true,
          magnifyingStone: true,
          vibrantGem: true,
          amnesicDriftwood: true, // всегда TRUE
        },

        leveling: "This mantra has no leveling effect", // This mantra has no leveling effect.

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "?",
          magnifyingStone: "?",
          vibrantGem: "?",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    veinbreaker: {
      info: {
        mantraName: "Veinbreaker★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: true,
          magnifyingStone: true,
          vibrantGem: true,
          amnesicDriftwood: true, // всегда TRUE
        },

        leveling: "This mantra has no leveling effect", // This mantra has no leveling effect.

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "?",
          magnifyingStone: "?",
          vibrantGem: "?",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    crymsonRain: {
      info: {
        mantraName: "Crimson Rain★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: true,
          magnifyingStone: true,
          vibrantGem: true,
          amnesicDriftwood: true, // всегда TRUE
        },

        leveling: "This mantra has no leveling effect", // This mantra has no leveling effect.

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "?",
          magnifyingStone: "?",
          vibrantGem: "?",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: true,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "?",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    veinTendrils: {
      info: {
        mantraName: "Vein Tendrils★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: true,
          magnifyingStone: true,
          vibrantGem: true,
          amnesicDriftwood: true, // всегда TRUE
        },

        leveling: "This mantra has no leveling effect", // This mantra has no leveling effect.

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "increases range",
          perfectLens: "increases range",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "?",
          magnifyingStone: "?",
          vibrantGem: "?",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    scarletCannon: {
      info: {
        mantraName: "Scarlet Cannon★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: true,
          magnifyingStone: true,
          vibrantGem: true,
          amnesicDriftwood: true, // всегда TRUE
        },

        leveling: "This mantra has no leveling effect", // This mantra has no leveling effect.

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "?",
          magnifyingStone: "?",
          vibrantGem: "?",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    razorBlitz: {
      info: {
        mantraName: "Razor Blitz★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: true,
          magnifyingStone: true,
          vibrantGem: true,
          amnesicDriftwood: true, // всегда TRUE
        },

        leveling: "This mantra has no leveling effect", // This mantra has no leveling effect.

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "?",
          magnifyingStone: "?",
          vibrantGem: "?",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    crimsonSurge: {
      info: {
        mantraName: "Crimson Surge★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: true,
          magnifyingStone: true,
          vibrantGem: true,
          amnesicDriftwood: true, // всегда TRUE
        },

        leveling: "This mantra has no leveling effect", // This mantra has no leveling effect.

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "?",
          magnifyingStone: "?",
          vibrantGem: "?",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    bloodtideRitual: {
      info: {
        mantraName: "Bloodtide Ritual★★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: true,
          magnifyingStone: true,
          vibrantGem: true,
          amnesicDriftwood: true, // всегда TRUE
        },

        leveling: "This mantra has no leveling effect", // This mantra has no leveling effect.

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "?",
          magnifyingStone: "?",
          vibrantGem: "?",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    bloodcurdle: {
      info: {
        mantraName: "Bloodcurdle★★★",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: true,
          magnifyingStone: true,
          vibrantGem: true,
          amnesicDriftwood: true, // всегда TRUE
        },

        leveling: "This mantra has no leveling effect", // This mantra has no leveling effect.

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "?",
          magnifyingStone: "?",
          vibrantGem:
            "increases the duration of the mantra without reducing damage per tick",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: true,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "?",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
  },
  hybrid: {
    flashfireSweep: {
      info: {
        mantraName: "Flashfire Sweep(TC+FC)",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true, // всегда TRUE
        },

        leveling: "", // This mantra has no leveling effect

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "Increases distance of the sweep.",
          perfectLens: "Increases distance of the sweep.",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "?",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    ironTether: {
      info: {
        mantraName: "Iron Tether(TC+IS)",
        modifiers: {
          cloudStone: true,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true, // всегда TRUE
        },

        leveling: "", // This mantra has no leveling effect

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "Increases duration of trap.",
          eternalLogstone: "Increases duration of trap.",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: true,
          multiplyingSpark: true,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "Makes the tether move.",
          multiplyingSpark: "Gives the user three tethers instead of one.",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    permafrostPrison: {
      info: {
        mantraName: "Permafrost Prison(FD+SC)",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true, // всегда TRUE
        },

        leveling: "", // This mantra has no leveling effect

        effect: {
          cloudStone: "Makes the prison bigger.",
          stratusStone: "Makes the prison bigger.",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "Makes it impale targets with more spikes.",
          eternalLogstone: "Makes it impale targets with more spikes.",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: true,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "Your prison now follows you.",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    tempestBlitz: {
      info: {
        mantraName: "Tempest Blitz(TC+GB)",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true, // всегда TRUE
        },

        leveling: "", // This mantra has no leveling effect

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
  },
  monstertMantras: {
    brachialSpear: {
      info: {
        mantraName: "Brachial Spear",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "No leveling effect.",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    coralSpear: {
      info: {
        mantraName: "Coral Spear",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "No leveling effect.",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    dreadBreath: {
      info: {
        mantraName: "Dread Breath",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "No leveling effect.",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    beastBurrow: {
      info: {
        mantraName: "Beast Burrow",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "No leveling effect.",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    enforcerPull: {
      info: {
        mantraName: "Enforcer Pull",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true, // всегда TRUE
        },
        leveling: "No leveling effect.",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "Increases the 'reach range' of Enforcer Pull.",
          perfectLens: "Increases the 'reach range' of Enforcer Pull.",
          aeonLogstone: "?",
          eternalLogstone: "?",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    mechaGatling: {
      info: {
        mantraName: "Mecha Gatling",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true, // всегда TRUE
        },
        leveling: "No leveling effect.",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    whirlingBlade: {
      info: {
        mantraName: "Whirling Blade",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: true,
          rushShard: true,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true, // всегда TRUE
        },
        leveling: "No leveling effect.",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "iccreases the amount of 'Whirl'attacks",
          eternalLogstone: "iccreases the amount of 'Whirl'attacks",
          driftShard: "Does absolutely nothing.",
          rushShard: "Does absolutely nothing.",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    abyssalRidge: {
      info: {
        mantraName: "Abyssal Ridge",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: true,
          rushShard: true,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true, // всегда TRUE
        },
        leveling: "No leveling effect.",
        effect: {
          cloudStone: "?",
          stratusStone: "?",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "?",
          eternalLogstone: "?",
          driftShard: "?",
          rushShard: "?",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
  },
  attumentLess: {
    strongLeft: {
      info: {
        mantraName: "STRONG LEFT",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling:
          " Increases destructive power per level. Decreases cooldown by (PLACEHOLDER) per level.",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    rapidPunches: {
      info: {
        mantraName: "Rapid Punches",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },
        leveling: "Increases damage per level.",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "?",
          perfectLens: "?",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "?",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    skyshatterKick: {
      info: {
        mantraName: "Skyshatter Kick",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "No leveling effect.",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
          roundSpark:
            "Stomp on the ground, launching your opponents and yourself upward.",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: true,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark:
            "Stomp on the ground, launching your opponents and yourself upward.",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    karitaLeap: {
      info: {
        mantraName: "Karita Leap",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "No leveling effect.",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "?",
          perfectLens: "?",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    karitaDivebomb: {
      info: {
        mantraName: "Karita Divebomb",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "No leveling effect.",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "??",
          perfectLens: "??",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
          tornadoSpark: "?",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: true,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "?",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    tacetDropKick: {
      info: {
        mantraName: "Tacet Drop Kick",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    rally: {
      info: {
        mantraName: "Rally",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          aeonLogstone: "?",
          eternalLogstone: "?",
          crystalLens: "?",
          perfectLens: "?",
          vibrantGem: "?",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    reinforce: {
      info: {
        mantraName: "Reinforce",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "Decreases cooldown of Reinforce by 5 seconds per level.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    brace: {
      info: {
        mantraName: "Brace",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling:
          "Increases duration by 2 seconds per level, up to a max duration of 8 seconds.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    shoulderBash: {
      info: {
        mantraName: "Shoulder Bash",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: true,
          rushShard: true,
          glassStone: true,
          magnifyingStone: true,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "Increases damage by an average of 6.76 per level",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: true,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "Shoulder bash now has knockback",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    dash: {
      info: {
        mantraName: "Dash",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "Increases distance of dash per level",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "Increases the length of the dash",
          perfectLens: "Increases the length of the dash",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: true,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark:
            "Turns Dash into 3 shorter dashes that can be used after each other",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    revenge: {
      info: {
        mantraName: "Revenge",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: true,
          rushShard: true,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "Increases the range of Revenge",
          rushShard: "Increases the range of Revenge",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    adrenalineSurge: {
      info: {
        mantraName: "Adrenaline Surge",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: true,
          rushShard: true,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "Reduces cooldown by 11.25 seconds per level",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: true,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    summonCauldron: {
      info: {
        mantraName: "Summon Cauldron",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    prediction: {
      info: {
        mantraName: "Prediction",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "Increases prediction frames by (PLACEHOLDER) every level.",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    gaze: {
      info: {
        mantraName: "Gaze",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },
        leveling: "",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "Increases range of invisible Gaze projectiles.",
          perfectLens: "Increases range of invisible Gaze projectiles.",
          aeonLogstone:
            "Increases time Gaze is up, but increases cooldown as well.",
          eternalLogstone:
            "Increases time Gaze is up, but increases cooldown as well.",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    glare: {
      info: {
        mantraName: "Glare",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    exhaustionStrike: {
      info: {
        mantraName: "Exhaustion Strike",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling:
          "Each level grants +12.5% more posture transfer, from 50% LV1, 62.5% LV2, 75% LV3, 87.5% LV4, 100% LV5. Damage changes: 20, 22, 24, 26, and 28 (+2 per level).",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    taunt: {
      info: {
        mantraName: "Taunt",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "Increases duration by 1s per level to a maximum of 15s.",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    sing: {
      info: {
        mantraName: "Sing",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: false,
        },
        leveling:
          "Each level increases duration by 1 second, from 7s at LV1 to 10s at LV4.",
        effect: {
          cloudStone:
            "Cloud Stones enhance your sing duration by +1 second per Cloud Stone per level.",
          stratusStone:
            "Stratus Stones make the Sing create a ‘gravity pull’ effect for each unit affected.",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "Enhances range by 50%.",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    Disguise: {
      info: {
        mantraName: "Disguise",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: true,
          multiplyingSpark: true,
          reversalSpark: true,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "Turns you into a Lantern.",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "Turns you into a Statue.",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "Turns you into a Crate.",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    CeaselessSlashes: {
      info: {
        mantraName: "Ceaseless Slashes",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: true,
          rushShard: true,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    MastersFlourish: {
      info: {
        mantraName: "Master's Flourish",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: true,
          magnifyingStone: true,
          vibrantGem: true,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "Does nothing visually",
          magnifyingStone: "Does nothing visually",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    Twincleave: {
      info: {
        mantraName: "Twincleave",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    ProminencDraw: {
      info: {
        mantraName: "Prominence Draw",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: true,
          magnifyingStone: true,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    FlashdrawStrike: {
      info: {
        mantraName: "Flashdraw Strike",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: true,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    PressureBlast: {
      info: {
        mantraName: "Pressure Blast",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: true,
          magnifyingStone: true,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    TableFlip: {
      info: {
        mantraName: "Table Flip",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling:
          "damage chenges 20,30,40,50,60 +10per level also has 2 str scaling.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    strongLeap: {
      info: {
        mantraName: "Strong Leap",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "",

        effect: {
          cloudStone: "?",
          stratusStone: "?",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    etherBarage: {
      info: {
        mantraName: "Ether Barage",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: true,
          rushShard: true,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },

        leveling: "",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "increases speed of projectiles",
          rushShard: "increases speed of projectiles",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    rapidSlashes: {
      info: {
        mantraName: "Rapid Slashes",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: true,
          rushShard: true,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },

        leveling: "",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "?",
          rushShard: "?",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    punishment: {
      info: {
        mantraName: "Punishment",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: true,
          magnifyingStone: true,
          vibrantGem: true,
          amnesicDriftwood: true,
        },

        leveling: "",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    onslaught: {
      info: {
        mantraName: "onslaught",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: true,
          magnifyingStone: true,
          vibrantGem: true,
          amnesicDriftwood: true,
        },

        leveling: "",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
  },
  oathBound: {
    arcSuit: {
      info: {
        mantraName: "Arc Suit (arcwarder)",
        modifiers: {
          cloudStone: true,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },
        leveling: "", // No leveling effect
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone:
            "Increases the duration of the Arc Suit up to a full minute, increases cooldown alongside this however.",
          eternalLogstone:
            "Increases the duration of the Arc Suit up to a full minute, increases cooldown alongside this however.",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem:
            "Gives Arc Suit physical resistance. If Arc Suit is active, your physical resistance is increased (14% max).",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    arcBeam: {
      info: {
        mantraName: "Arc Beam   (arcwarder)",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "", // No leveling effect
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    arcWave: {
      info: {
        mantraName: "Arc Wave (arcwarder)",
        modifiers: {
          cloudStone: true,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "", // No leveling effect
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    mindsoothe: {
      info: {
        mantraName: "Mindsoothe  (blindseer)",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: true,
          magnifyingStone: true,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "", // No leveling effect
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "Increases range of healing.",
          magnifyingStone: "Increases range of healing.",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    tranquilCircle: {
      info: {
        mantraName: "Tranquil Circle (blindseer)",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: true,
          magnifyingStone: true,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "", // No leveling effect
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: true,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark:
            "Makes the Tranquil Circle smaller, but also makes the circle follow you; however, it does not work in water.",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    SightlessBeam: {
      info: {
        mantraName: "Sightless Beam (blindseer)",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: true,
          magnifyingStone: true,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone:
            "increases the duration of the cast, but the overall damage becomes less",
          magnifyingStone:
            "increases the duration of the cast, but the overall damage becomes less",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    Equalizer: {
      info: {
        mantraName: "Equalizer  (contractor)",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    LordSlice: {
      info: {
        mantraName: "Lord/s Slice  (contractor)",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    Judgement: {
      info: {
        mantraName: "Judgement   (contractor)",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    RadiantKick: {
      info: {
        mantraName: "Radiant Kick (dawnwakler)",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    BlindingDawn: {
      info: {
        mantraName: "Blinding Dawn (dawnwakler)",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },
        leveling: "",
        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },
        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },
        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    RadiantDawn: {
      info: {
        mantraName: "Radiant Dawn (dawnwakler)",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "?",
          perfectLens: "?",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    PrecisionCuts: {
      info: {
        mantraName: "Precision Cuts (fadetrimmer)",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    CloseShave: {
      info: {
        mantraName: "Close Shave (fadetrimmer)",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    FakeStrike: {
      info: {
        mantraName: "Fake Strike (jetstriker)",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "??",
          perfectLens: "??",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "??",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    JetKick: {
      info: {
        mantraName: "Jet Kick  (jetstriker)",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: true,
          perfectLens: true,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "???",
          perfectLens: "???",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "???",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    SymbioticSustain: {
      info: {
        mantraName: "Symbiotic Sustain (linkstrider)",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    ParasiticLeech: {
      info: {
        mantraName: "Parasitic leech  (linkstrider)",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    Ascension: {
      info: {
        mantraName: "Ascension (starkindred)",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "?",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    SinisterHalo: {
      info: {
        mantraName: "Sinister Halo  (starkindred)",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "?",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    CelestialAssault: {
      info: {
        mantraName: "Celestial Assault  (starkindred)",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: true,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "?",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    IllusoryCounter: {
      info: {
        mantraName: "Illusory Counter  (vision shaper)",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    MirrorIllusion: {
      info: {
        mantraName: "Mirror illusion (vision shaper)",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "Increases Illusory Realm duration.",
          eternalLogstone: "Increases Illusory Realm duration.",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: true,
          multiplyingSpark: true,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "Makes you the 'center' of the realm.",
          multiplyingSpark: "Makes the realm attempt more catches.",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },

    IllusoryServants: {
      info: {
        mantraName: "Illusory Servants (vision shaper)",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone:
            "Makes the Illusory Servants last longer before disappearing.",
          eternalLogstone:
            "Makes the Illusory Servants last longer before disappearing.",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    lethalInjection: {
      info: {
        mantraName: "Lethal Injection (saltchemist)",
        modifiers: {
          cloudStone: false,
          stratusStone: false,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: false,
          eternalLogstone: false,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    restrain: {
      info: {
        mantraName: "restrain   (chainwarden)",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    repture: {
      info: {
        mantraName: "repture   (chainwarden)",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: true,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "instead of knocking them away, it pulls them to you",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    impel: {
      info: {
        mantraName: "impel   (chainwarden)",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    palmStrike: {
      info: {
        mantraName: "Palm Strike   (bladeharper)",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
    decimate: {
      info: {
        mantraName: "Decimate   (bladeharper)",
        modifiers: {
          cloudStone: true,
          stratusStone: true,
          crystalLens: false,
          perfectLens: false,
          aeonLogstone: true,
          eternalLogstone: true,
          driftShard: false,
          rushShard: false,
          glassStone: false,
          magnifyingStone: false,
          vibrantGem: false,
          amnesicDriftwood: true,
        },

        leveling: "This mantra has no leveling effect.",

        effect: {
          cloudStone: "",
          stratusStone: "",
          crystalLens: "",
          perfectLens: "",
          aeonLogstone: "",
          eternalLogstone: "",
          driftShard: "",
          rushShard: "",
          glassStone: "",
          magnifyingStone: "",
          vibrantGem: "",
        },

        sparks: {
          blastSpark: false,
          magnetSpark: false,
          multiplyingSpark: false,
          reversalSpark: false,
          roundSpark: false,
          springSpark: false,
          tornadoSpark: false,
          mirageStone: false,
          falseflameStone: false,
          hauntingStone: false,
        },

        sparksEffect: {
          blastSpark: "",
          magnetSpark: "",
          multiplyingSpark: "",
          reversalSpark: "",
          roundSpark: "",
          springSpark: "",
          tornadoSpark: "",
          mirageStone: "",
          falseflameStone: "",
          hauntingStone: "",
        },
      },
    },
  },
};

export default mantras;
